import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './S03.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(
    () => {
      const interval = setInterval(() => {
        setActiveIndex((prevActiveIndex) => {
          return (prevActiveIndex + 1) % 2;
        });
      }, 2500);

      return () => clearInterval(interval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('left')}>
          <h1>Our products</h1>
          <p>
            We had to leave areas that can not be covered by it.
            <br />
            The drones have changed the word 'danger' to <span>safe</span>.
          </p>
          <div className={cx('button')}>To be announced</div>
        </div>
        <div className={cx('right')}>
          <img
            src={require('../../assets/images/home/product-01.png')}
            alt="Drone Payload Solution"
            draggable={false}
            className={cx({ active: activeIndex === 0 })}
          />
          <img
            src={require('../../assets/images/home/product-02.png')}
            alt="Drone Payload Solution"
            draggable={false}
            className={cx({ active: activeIndex === 1 })}
          />
        </div>
      </div>
    </div>
  );
};

export default Section;
