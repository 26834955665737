import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import styles from './Header.module.scss';

const cx = classNames.bind(styles);

const Header = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <Link to="/">
          <img
            src={require('../assets/images/logo-header.png')}
            alt="QUATERNION"
            draggable={false}
            className={cx('logo')}
          />
        </Link>
        <ul className={cx('gnb')}>
          {/* <li>ABOUT US</li> */}
          {/* <li>PRODUCTS</li> */}
          <li>
            <Link to="/contact-us">CONTACT US</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
