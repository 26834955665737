import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/home';
import Contact from './pages/contact';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollTop from './components/ScrollTop';

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/contact-us" exact element={<Contact />} />
      </Routes>
      <Footer />
      <ScrollTop />
    </BrowserRouter>
  );
};

export default App;
