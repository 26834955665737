import React from 'react';

import S01 from './S01';
import S02 from './S02';

const Contact = () => {
  return (
    <div>
      <S01 />
      <S02 />
    </div>
  );
};

export default Contact;
