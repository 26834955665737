import React from 'react';

import S01 from './S01';
import S02 from './S02';
import S03 from './S03';
import S04 from './S04';
import S05 from './S05';

const Home = () => {
  return (
    <div>
      <S01 />
      <S02 />
      <S03 />
      <S04 />
      <S05 />
    </div>
  );
};

export default Home;
