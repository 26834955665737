import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { FaPlay } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';

import styles from './S05.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const [showVideo, setShowVideo] = useState(false);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const playVideo = () => {
    if (isMobile) {
      window.open(require('../../assets/videos/drone_and_station.mp4'));
      return;
    }

    toggleVideo();
  };

  return (
    <div className={cx('container')}>
      {showVideo && (
        <video autoPlay onEnded={toggleVideo}>
          <source
            src={require('../../assets/videos/drone_and_station.mp4')}
            type="video/mp4"
          />
          <div className={cx('dim')} />
        </video>
      )}
      {!showVideo && (
        <div className={cx('wrapper')}>
          <h1>Tether Drone and Station</h1>
          <p>
            In Korea's west coast, casualties occur every year on tidal flats.
            <br />
            However, it takes a lot of time for drones to be dispatched to the
            scene, and it is even more difficult to prevent accidents.
            <br />
            Tether drones and stations were developed to solve these problems.
          </p>
          <div className={cx('button')} onClick={playVideo}>
            Play a video <FaPlay className={cx('play')} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Section;
