import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './S04.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const [activeIndex, setActiveIndex] = useState();

  useEffect(
    () => {
      setActiveIndex(0);

      const interval = setInterval(() => {
        setActiveIndex((prevActiveIndex) => {
          return (prevActiveIndex + 1) % 3;
        });
      }, 5000);

      return () => clearInterval(interval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <h1>
          <span>QUATERNION</span>’s safety solutions are <br />
          the No.1 safety solutions <br />
          on the market for commercial drones.
        </h1>
        <div className={cx('slider')}>
          <div className={cx('imageWrapper')}>
            <img
              src={require('../../assets/images/home/slide-01.png')}
              alt="Flight Control System"
              draggable={false}
              className={cx({ active: activeIndex === 0 })}
            />
            <img
              src={require('../../assets/images/home/slide-02.png')}
              alt="Flight Control System"
              draggable={false}
              className={cx({ active: activeIndex === 1 })}
            />
            <img
              src={require('../../assets/images/home/slide-03.png')}
              alt="Flight Control System"
              draggable={false}
              className={cx({ active: activeIndex === 2 })}
            />
          </div>
          <ul className={cx('items')}>
            <li className={cx(['item', { active: activeIndex === 0 }])}>
              <div className={cx('gauge')} />
              <h3>Flight Control System</h3>
              <p>
                Quaternion's flight control system(FCS) is a highly reliable
                system developed with its own technology, not open source. It
                can be used for industrial and military purposes by applying
                self-developed control algorithms, flight control logic, and
                optimal sensors according to the operating environment.
              </p>
            </li>
            <li className={cx(['item', { active: activeIndex === 1 }])}>
              <div className={cx('gauge')} />
              <h3>Drone Payload Solution</h3>
              <p>
                Quaternion is developing a variety of payload's by integrating
                sensor modules directly. Payload's can respond effectively to
                disaster situations, search and rescue as well as accident
                prevention.
              </p>
            </li>
            <li className={cx(['item', { active: activeIndex === 2 }])}>
              <div className={cx('gauge')} />
              <h3>Drone Station Solution</h3>
              <p>
                The drone station is installed in the operating area and can be
                operated at the control center without the need for a pilot on
                the site. The station's control system is designed to be based
                on the flight control system and can handle various error
                situations.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Section;
