import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import styles from './S02.module.scss';

const cx = classNames.bind(styles);
gsap.registerPlugin(ScrollTrigger);

const Section = () => {
  const observeRef = useRef();
  const marqueeRef = useRef();

  const [isIn, setIn] = useState(false);

  useEffect(() => {
    // Change background color
    if (observeRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIn(entry.isIntersecting);
        },
        { threshold: 0.2 }
      );
      observer.observe(observeRef.current);
    }

    // Marquee by scroll
    gsap.to(marqueeRef.current, {
      x: 1200,
      scrollTrigger: {
        trigger: marqueeRef.current,
        start: 'top bottom',
        end: 'top top',
        scrub: true,
      },
    });
  }, []);

  return (
    <div className={cx(['container', { dark: isIn }])}>
      <div ref={observeRef} className={cx('inArea')} />
      <h2>INTEGRATED DRONE CONTROL PLATFORM PROVIDER</h2>
      <h1>
        Our mission is to create and pioneer solutions, <br />
        for <span>safety</span> from the sky to the space.
      </h1>
      <div className={cx('button')}>To be announced</div>
      <div className={cx('wrapper')}>
        <p ref={marqueeRef} className={cx('marquee')}>
          <span>Safety Is More Important Than Technology. </span>
          <span>Safety Is More Important Than Technology. </span>
          <span>Safety Is More Important Than Technology. </span>
          <span>Safety Is More Important Than Technology. </span>
        </p>
        <img
          src={require('../../assets/images/home/drone.png')}
          className={cx('drone')}
          alt="Safety Is More Important Than Technology."
          draggable={false}
        />
      </div>
    </div>
  );
};

export default Section;
