import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { FaCheck, FaArrowRight } from 'react-icons/fa';
import axios from 'axios';

import styles from './S01.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    country: null,
    phone: '',
    application: '',
    job: '',
    message: '',
    agree: false,
  });

  const onChangeHandle = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const toggleCheck = () => {
    setInfo({
      ...info,
      agree: !info.agree,
    });
  };

  const doSubmit = () => {
    if (info.firstName.trim() === '') {
      alert('Please enter a first name.');
      return;
    }
    if (info.lastName.trim() === '') {
      alert('Please enter a last name.');
      return;
    }
    if (info.company.trim() === '') {
      alert('Please enter a company.');
      return;
    }
    if (info.email.trim() === '') {
      alert('Please enter a email.');
      return;
    }
    if (info.country.trim() === '') {
      alert('Please enter a country.');
      return;
    }
    if (info.phone.trim() === '') {
      alert('Please enter a phone number.');
      return;
    }
    if (!info.agree) {
      alert('You must agree to the Privacy Policy.');
      return;
    }

    // 메일 발송
    axios.post('https://flymail.apis.flyground.co.kr/send', {
      key: '0d7d5156-2d39-11ed-a261-0242ac120002',
      to: ['jh.noh@quaternion.co.kr'],
      subject: 'Message from website',
      html: `
          <ul>
            <li>Name : ${info.firstName} ${info.lastName}<br /></li>
            <li>Company : ${info.company}<br /></li>
            <li>Email : ${info.email}<br /></li>
            <li>Country : ${info.country}<br /></li>
            <li>Phone Number : ${info.phone}<br /></li>
            <li>Application${info.application}<br /></li>
            <li>Job : ${info.job}<br /></li>
            <li>Message<br />${info.message}</li>
          </ul>
        `,
    });

    alert('Registration is complete.');
    navigate('/');
  };

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('form')}>
          <h1>Contact us</h1>
          <div className={cx('row')}>
            <div className={cx('inputItem')}>
              <label htmlFor="firstName">
                First Name<span>*</span>
              </label>
              <input
                name="firstName"
                value={info.firstName}
                onChange={onChangeHandle}
              />
            </div>
            <div className={cx('inputItem')}>
              <label htmlFor="lastName">
                Last Name<span>*</span>
              </label>
              <input
                name="lastName"
                value={info.lastName}
                onChange={onChangeHandle}
              />
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('inputItem')}>
              <label htmlFor="company">
                Company<span>*</span>
              </label>
              <input
                name="company"
                value={info.company}
                onChange={onChangeHandle}
              />
            </div>
            <div className={cx('inputItem')}>
              <label htmlFor="email">
                Email<span>*</span>
              </label>
              <input
                name="email"
                value={info.email}
                onChange={onChangeHandle}
              />
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('inputItem')}>
              <label htmlFor="country">
                Country<span>*</span>
              </label>
              <input
                name="country"
                value={info.country}
                onChange={onChangeHandle}
              />
            </div>
            <div className={cx('inputItem')}>
              <label htmlFor="phone">
                Phone Number<span>*</span>
              </label>
              <input
                name="phone"
                value={info.phone}
                onChange={onChangeHandle}
              />
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('inputItem')}>
              <label htmlFor="application">For what application?</label>
              <input
                name="application"
                value={info.application}
                onChange={onChangeHandle}
              />
            </div>
            <div className={cx('inputItem')}>
              <label htmlFor="job">Job Title</label>
              <input name="job" value={info.job} onChange={onChangeHandle} />
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('inputItem')}>
              <label htmlFor="message">Message</label>
              <textarea name="message" onChange={onChangeHandle}>
                {info.message}
              </textarea>
            </div>
          </div>
          <div className={cx(['row', 'bottom'])}>
            <div className={cx('agreeItem')}>
              <div className={cx('checkbox')} onClick={toggleCheck}>
                {info.agree && <FaCheck size={14} />}
              </div>
              <div className={cx('privacyText')}>
                I Agree to Privacy Policy.
              </div>
            </div>
            <div className={cx('button')} onClick={doSubmit}>
              Submit <FaArrowRight className={cx('arrow')} />
            </div>
          </div>
        </div>
        <div className={cx('side')} />
      </div>
    </div>
  );
};

export default Section;
