import React from 'react';
import classNames from 'classnames/bind';

import styles from './S01.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const doScroll = () => {
    window.scroll({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className={cx('container')}>
      <video autoPlay muted loop>
        <source
          src={require('../../assets/videos/main.mp4')}
          type="video/mp4"
        />
      </video>
      <div className={cx('wrapper')}>
        <h1>
          <span>Safety</span> Is More Important
          <br />
          Than Technology.
        </h1>
        <div className={cx('arrow')} onClick={doScroll} />
      </div>
    </div>
  );
};

export default Section;
