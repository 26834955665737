import React from 'react';
import classNames from 'classnames/bind';

import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

const Footer = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('left')}>
          <img
            src={require('../assets/images/logo-footer.png')}
            alt="QUATERNION"
            draggable={false}
          />
          <h5>Social Media</h5>
          <ul className={cx('sns')}>
            <li className={cx('button')}>
              <a
                href="https://www.youtube.com/channel/UCjYlylFSYiV1aWIuXfzxd6A"
                target="_blank"
                rel="noreferrer"
              >
                YOUTUBE
              </a>
            </li>
            <li className={cx('button')}>
              <a
                href="https://facebook.com/we.are.quaternion"
                target="_blank"
                rel="noreferrer"
              >
                FACEBOOK
              </a>
            </li>
          </ul>
        </div>
        <div className={cx('right')}>
          <div className={cx('email')}>
            <a
              href="mailto:jh.noh@quaternion.co.kr"
              target="_blank"
              rel="noreferrer"
            >
              jh.noh@quaternion.co.kr
            </a>
          </div>
          <ul>
            <li>
              <h5>Tel</h5>
              <div className={cx('contact')}>+82-2-3159-1851</div>
            </li>
            <li>
              <h5>Fax</h5>
              <div className={cx('contact')}>+82-2-3159-1850</div>
            </li>
            <li style={{ flex: 1.5 }}>
              <h5>Address</h5>
              <div className={cx('contact')}>
                76 Hanggongdaehang-ro, Hwajeon-dong, Deokyang-gu, Goyang-si,
                Gyeonggi-do
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={cx('bottom')}>© 2022 QUATERTNION</div>
    </div>
  );
};

export default Footer;
